const API_BASE_PATH = process.env.API_BASE_PATH || '';

function getFetchHeaders(payload = {}, method, urlencoded, neteller, additionalHeaders, additionalOptions) {
	const appJson = 'application/json; charset=utf-8';
	const appUrle = 'application/x-www-form-urlencoded; charset=UTF-8';
	const contentType = urlencoded ? appUrle : appJson;
	const optionsDef = {
		headers: {
			'Content-Type': contentType
		},
		cache: 'no-store',
		credentials: 'include'
	};
	const optionsNeteller = {
		headers: {
			'Content-Type': appJson,
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Accept: 'application/json, text/javascript, */*; q=0.01',
			'X-Requested-With': 'XMLHttpRequest',
			Expires: '0',
			'Accept-Encoding': 'gzip, deflate, br'
		},
		credentials: 'include'
	};
	let options = neteller ? optionsNeteller : optionsDef;
	if (payload instanceof FormData) {
		delete options.headers['Content-Type'];
	}
	if (additionalHeaders) {
		options = {
			...options,
			headers: {
				...options.headers,
				...additionalHeaders
			}
		};
	}
	if (additionalOptions) {
		options = {
			...options,
			...additionalOptions
		};
	}
	if (method === 'GET' || method === 'HEAD') {
		return options;
	}

	// TODO: if the content-type is urlencoded, the payload is a urlencoded string
	// like key1=value1&key2=value2&key3=value3
	// This may be handled here or elsewhere, or show warning.

	const body = typeof payload === 'string' || payload instanceof FormData ? payload : JSON.stringify(payload);
	// in case of the payload is a string, the JSON.stringify will wrap
	// the string inside extra quotations.
	// JSON.stringify("foo") === ""foo"
	// body: JSON.stringify(payload)

	return {
		...options,
		...{
			method,
			body
		}
	};
}

export const doAPIRequest = ({
	endpoint = '/',
	payload = {},
	method = 'GET',
	urlencodedContentType = false,
	netellerHeaders = false,
	additionalHeaders = {},
	additionalOptions = {}
}) => {
	const headers = getFetchHeaders(
		payload,
		method,
		urlencodedContentType,
		netellerHeaders,
		additionalHeaders,
		additionalOptions
	);
	return fetch(`${API_BASE_PATH}${endpoint}`, headers);
};

async function requestApi(
	endpoint = '/',
	payload = {},
	method = 'GET',
	urlencodedContentType = false,
	netellerHeaders = false,
	additionalHeaders = {},
	additionalOptions = {}
) {
	let response;
	try {
		response = await doAPIRequest({
			endpoint,
			payload,
			method,
			urlencodedContentType,
			netellerHeaders,
			additionalHeaders,
			additionalOptions
		});
		return await response.json();
	} catch (err) {
		throw new Error(`${endpoint}, ${err}`, { cause: err });
	}
}

const parseResponse = ({
	status = '',
	errorCode = '',
	errorDescription = '',
	result = { error: { errorCode, errorDescription, status } }
}) => result;

export { requestApi, parseResponse };
